/* Print styling, load separately */
@page {
  orphans: 4;
  widows: 3;
  size: auto;
  /* auto is the initial value */
  margin: 1.5cm;
}
html {
  font-size: 10px;
  line-height: 12px;
  display: inline;
  margin: 0 !important;
  padding: 0 !important;
  overflow: visible;
}

body {
  font-size: 10px;
  border: none;
  background: white;
}

.content-header {
  font-size: 16px;
}

.header {
  font-size: 14px !important;
  color: var(--text-dark);
}

.ribbon-tile {
  display: block;
}

.ribbon {
  max-width: unset;
  margin-right: 0;
  margin: 0 0 calc(var(--spacing) * 2) 0;
  padding: calc(var(--spacing) * 2) 0;
  display: flex;
  column-gap: var(--spacing);
  border-bottom: 1px solid var(--border);
  border-top: 1px solid var(--border);
}

.ribbon__section {
  padding: 0;
  flex: 1 1 33%;
}
.ribbon__section .key-value__key {
  font-size: 12px !important;
  color: var(--text-dark);
}

.key-value__value, .badge {
  font-size: 10px;
}

.tile {
  padding: 0;
  margin-bottom: calc(var(--spacing) * 2);
  border-bottom: 1px solid var(--border);
  padding-bottom: calc(var(--spacing) * 2);
  page-break-inside: avoid;
  margin-top: 0;
}
.tile .key-value__key {
  font-size: 10px !important;
}
.tile--report-media {
  /*Hide report media*/
  display: none;
}

.tooltip:after {
  display: inline;
  opacity: 1;
  visibility: visible;
  position: relative;
  color: var(--faded);
  background: none;
  padding: 0;
  margin: 0;
  font-size: 10px;
  left: 0.25rem;
  right: unset;
  bottom: 0.25rem;
  top: unset;
}
.tooltip:before {
  display: none;
}

.research-wrapper {
  background: none;
  padding: 0;
  margin-top: 0;
  font-size: 10px;
  page-break-inside: avoid;
}

.research-header {
  padding-top: 0;
  box-shadow: none;
}
.research-header .research__title, .research-header .research__subtitle {
  margin-right: 1rem;
}
.research-header .research__subtitle {
  font-weight: 600;
  font-size: 12px;
}

.research-result-list__title {
  font-size: 12px;
}

.table__header {
  font-size: 10px;
}

.research-result {
  flex: 1 1 100%;
  margin-bottom: 1rem;
}
.research-result:hover {
  background: none;
}
.research-result--highlight {
  border-radius: 4px;
  background: var(--background--light);
}
.research-result--highlight:hover {
  background: var(--background--light);
}
.research-result-list--photos, .research-result-list--occupation {
  margin-bottom: calc(var(--spacing) * 0.5);
}

.section-toggle:before {
  display: none;
}

.menu-wrapper {
  display: none !important;
}

.content-wrapper {
  margin: 0;
  display: block;
  width: 100%;
  background: none;
}
.content-wrapper__inner {
  display: inline;
  min-height: unset;
  margin: 0 !important;
}

.content-header__actions {
  display: none;
}

.sidebar {
  display: none !important;
}

.results-wrapper__inner {
  display: block;
  padding-bottom: 0 !important;
}

.table-wrapper {
  height: unset !important;
  display: inline !important;
}

.table {
  display: inline !important;
}
.table p {
  margin: 0;
}

.loader {
  display: none;
}

.section-wrapper {
  display: block !important;
}

.tabs {
  display: block;
}
.tabs label {
  margin-top: 2rem !important;
  page-break-before: always;
  display: block;
}

.tab {
  display: inline !important;
  height: initial !important;
  opacity: 1 !important;
  overflow: visible;
}
.tab__link:after {
  display: none;
}

.research-wrapper {
  display: none;
}

.research-wrapper-report-preview {
  display: block;
}

.modal-wrapper {
  margin-bottom: calc(var(--spacing) * 2);
  border-bottom: 1px solid var(--border);
  padding-bottom: calc(var(--spacing) * 2);
  position: relative;
  page-break-after: always;
}
.modal-wrapper--inactive {
  display: inherit;
}
.modal-wrapper .overlay {
  display: none;
}
.modal-wrapper .modal {
  height: unset;
  position: relative;
  max-width: unset;
  padding: 0;
}
.modal-wrapper .modal__content {
  padding: 0;
  margin: 0;
}
.modal-wrapper .modal .research-wrapper {
  display: block;
}
.modal-wrapper .modal .button--close {
  display: none;
}
.modal-wrapper .modal .file-counter {
  display: none;
}
.modal-wrapper .modal .section-wrapper {
  height: unset;
}
.modal-wrapper .modal .section-toggle {
  pointer-events: none;
}
.modal-wrapper .modal .section-toggle:before {
  display: none;
}

.button {
  display: none;
}

.preview-toggle {
  display: none;
}

.preview-wrapper {
  word-break: break-all;
}
.preview-wrapper:after {
  display: none;
}
.preview-wrapper .preview-target {
  opacity: inherit;
  display: inline;
}

.value-list {
  max-height: 100%;
  overflow: inherit;
}
.value-list .key-value__value.key-value__value--horizontal {
  display: inline;
}

/*# sourceMappingURL=wis-aviator-print.css.map */
